import React from "react";
import classnames from "classnames";

import "./Card.scss";

export type ColorsType =
  | "transparent"
  | "white"
  | "turquoiseSurf"
  | "palatinatePurple"
  | "blackLive";

export interface Props {
  children?: any;
  className?: string;
  color: ColorsType;
}

const COLORS = {
  transparent: "card--transparent",
  white: "card--white",
  turquoiseSurf: "card--turquoise-surf",
  palatinatePurple: "card--palatinate-purple",
  blackLive: "card--black-live",
};

const Card = ({ children, className, color }: Props) => {
  const colorClass = COLORS[color];
  return (
    <div className={classnames("card", colorClass, className)}>{children}</div>
  );
};

export default Card;
