import React from "react";
import { SealService } from "@vereign/lib-seal";
import "../styles/main.scss";
import VerificationPageContainer from "../routes/VerificationPage";
// import Header from "./Header";
import Footer from "./Footer";
import GreetingsPage from "../routes/GreetingsPage";

// import vereignLogo from '../assets/images/vereign-logo.svg';
// import Svg from "./Svg";
import "./App.scss";

function App() {
  const sealHead = SealService.getSealHead(window.location.href);

  return (
    <section className="app">
      {/* <Header /> */}
      {/* {<Svg />} */}
      {/* <div className="app__background">
        <img src={vereignLogo} className="app__background__image"/>
      </div> */}
      {sealHead ? <VerificationPageContainer /> : <GreetingsPage />}
      <Footer />
    </section>
  );
}

export default App;
