import React from "react";
import classnames from "classnames";
import spinnerBlack from "../../assets/images/spinner-black.svg";
import spinnerWhite from "../../assets/images/spinner-white.svg";
import spinnerTurq from "../../assets/images/spinner-turq.svg";

import "./Spinner.scss";

type ColorsType = "white" | "black" | "turq";

interface Props {
  width?: number | string;
  height?: number | string;
  className?: string;
  color?: ColorsType;
}

const Spinner = ({ className, width, height, color = "black" }: Props) => {
  
  let icon = (() => {
    switch (true) {
      case color === 'white':
        return spinnerWhite;
      case color === 'black':
        return spinnerBlack;
      case color === 'turq':
        return spinnerTurq;
      default:
        return spinnerBlack;
    }
  })();
  
  return (
    <img
      alt="spinner"
      width={width}
      height={height}
      className={classnames("spinner", className)}
      src={icon}
    />
  );
};

Spinner.defaultProps = {
  width: 32,
  height: 32,
};

export default Spinner;
