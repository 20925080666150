import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./components/App";
import { loadConfig } from "./config";
import logger from "./logger";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const run = async () => {
  try {
    await loadConfig();
  } catch (e) {
    logger.error(`Error loading configuration ${e.message}.`);

    if (process.env.NODE_ENV === "production") {
      return;
    }

    logger.info(`Falling back to default config.`);
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
};

run();
