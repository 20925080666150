import React from "react";
import { Attachment, MessageData } from "@vereign/light-utils";

// Ignore TS errors until we have type definition for react-ui
// @ts-ignore
import { Tooltip } from "@vereign/react-ui";
import "@vereign/react-ui/src/components/tooltip/Tooltip.scss";
import copyHash from "../../../../assets/images/seal_e-mail/hash.svg";
import downloadIcon from "../../../../assets/images/seal_e-mail/download.svg";
import Icon from "../../../../components/Icon";
import "./AttachmentsCard.scss";

import useIpfsAttachments from "../../../../hooks/useIpfsAttachments";
import { base64ToHex, convertByteSizeToReadable, copyToClipboard, generateColorFromString } from "../../../../utils/stringUtils";
import Spinner from "../../../../components/Spinner";
import chainLinksIcon from "../../../../assets/images/chain-links.svg";
import { sumOfArray } from "../../../../utils/generalUtils";
import { truncateString } from '../../../../utils/stringUtils';

interface Props {
  qrCodeData?: MessageData;
}

const AttachmentsCard = ({ qrCodeData }: Props) => {
  const attachments = qrCodeData?.attachments;
  const ipfs = qrCodeData?.ipfs;

  const { loadingHashes, downloadAttachment, downloadAllAttachments } = useIpfsAttachments(ipfs);


  const downloadAll = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault()

    let allAttachmentsArray = [];
    if (attachments) {
      for (let i=0; i < attachments?.length; i++) {
        allAttachmentsArray.push({
          hash: attachments[i].hash, 
          name: attachments[i].name,
        })
      }
    }

    if (allAttachmentsArray.length < 1) {
      return
    }

    downloadAllAttachments(allAttachmentsArray);
  }

  const renderDownloadButton = (attachment: Attachment) => {
    if (!ipfs) return;

    let nameSplit = attachment.name.split('.')

    let fileExtension = nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : ''
    
    let fileColorHex = generateColorFromString(fileExtension);
    let fileColorHexDarker = generateColorFromString(fileExtension, 75, 60);

    return (
      <div>
        <div
          className={loadingHashes[attachment.hash] ? 'attachments-card__loader' : 'attachments-card__loader attachments-card__loader--hidden'}
        >
          <Spinner
            width={24}
            height={24}
            className="attachments-card__attachment-download"
          />
        </div>
        <button 
          className="attachments-card__button"
          onClick={() => downloadAttachment(attachment.hash, attachment.name)}
        >
        <div 
          className="attachments-card__button--left"
        >
          <svg height='76px' viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000">
            <g>
              <path d="M576 102.4H268.8c-14.08 0-25.6 11.52-25.6 25.6v742.4c0 14.08 11.52 25.6 25.6 25.6h512c14.08 0 25.6-11.52 25.6-25.6V332.8L576 102.4z" fill={fileColorHexDarker}></path>
              <path d="M576 307.2c0 14.08 11.52 25.6 25.6 25.6h204.8L576 102.4v204.8z" fill={fileColorHex}></path>
            </g>
          </svg>
          <span>{truncateString(fileExtension, 4)}</span>
        </div>
        <div className="attachments-card__button--right">
          <span>{truncateString(attachment.name, 23)}</span>
          <div className="attachments-card__button__bottom">
            <span>{attachment.size ? convertByteSizeToReadable(attachment.size) : 'NaN'}</span>
            <a
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                copyToClipboard(
                  attachment?.hash ? base64ToHex(attachment?.hash) : "N/A"
                );
              }}
            >
              Copy hash 
              <Icon
                className="attachments-card__copy-icon"
                width={12}
                height={12}
                src={copyHash}
                alt="copy hash button"
              />
            </a>
          </div>
        </div>
      </button>
      </div>
    );
  };

  return (
    <div className="attachments__doublerow">
      {attachments && attachments.length > 0 && 
        <>
          <div className="attachments-card__titlestats">
            <div className="attachments-card__titlestats__info">
              <Icon
                className="attachments-card__copy-icon"
                width={20}
                height={20}
                src={chainLinksIcon}
                alt="copy hash button"
              />
              <p>{attachments.length} {attachments.length === 1 ? 'attachment' : 'attachments'} {` `}
                <span>
                  ({
                    convertByteSizeToReadable(
                      sumOfArray(
                        {arr: attachments, initialValue: 0, arrayObjectKey: 'size'}
                      )
                    )
                  })
                </span>
              </p>
            </div>
            {attachments.length > 0 &&
              <button
                onClick={(ev) => downloadAll(ev)}
                className="attachments-card__titlestats__download"
              >
                <Icon
                  className="attachments-card__copy-icon"
                  width={20}
                  height={20}
                  src={downloadIcon}
                  alt="donwload all button"
                />
                <span>Download all</span>
              </button>
            }
          </div>
          <div className="attachments-card__attachments-list">
            {attachments.map((attachment, i) => {

              return (
              
              <div className="attachments-card__attachments-list-item" key={i}>
                <div className="attachments-card__buttons">
                  <Tooltip text="Download attachment">
                    {renderDownloadButton(attachment)}
                  </Tooltip>
                </div>
              </div>
              
            )})}
          </div>
        </>
      }
    </div>
  );
};

export default AttachmentsCard;
