import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import "./Details.scss";
import {
  STATE_ERROR,
  STATE_LOADING_STATUS_DATA,
  STATE_PENDING,
  STATE_STATUS_DATA_PENDING,
  STATE_TAIL_PENDING,
  STATE_UNVERIFIED,
  STATE_VERIFYING_ATTACHMENTS,
  STATE_VERIFYING_RECEIVERS,
  STATE_VERIFYING_SENDER,
} from "../../../hooks/useVerificationFlow";

import VerificationStepCard from "./VerificationStepCard";

import {
  MessageData,
  StatusData,
  VerificationData,
} from "@vereign/light-utils";
import BlockchainInfo from "./BlockchainInfo";
import IPFSInfo from "./IPFSInfo";
import Card from "../../../components/Card";

interface Props {
  verificationState: number;
  error?: Error;
  className?: string;
  sealTime?: number;
  sentTime?: number;
  senderStatusData?: StatusData;
  senderVerificationDetails?: VerificationData;
  qrCodeData?: MessageData;
  numReceiversVerified: number;
  sealId?: string;
}

const Details = ({
  verificationState,
  error,
  className,
  sealTime,
  sentTime,
  senderStatusData,
  senderVerificationDetails,
  numReceiversVerified,
  qrCodeData,
  sealId,
}: Props) => {
  const getVerificationStatusText = () => {
    switch (verificationState) {
      case STATE_LOADING_STATUS_DATA:
        return "Loading statuses data";
      case STATE_VERIFYING_SENDER:
        return "Verifying sender's status";
      case STATE_VERIFYING_RECEIVERS:
        return "Verifying statuses of receivers";
      case STATE_VERIFYING_ATTACHMENTS:
        return "Verifying attachments";
      case STATE_STATUS_DATA_PENDING:
        return "Pending for anchoring in blockchain. Please try again later.";
      case STATE_TAIL_PENDING:
        return "Seal tail is pending to be uploaded. Please try again later.";
      case STATE_ERROR:
        return "Something went wrong. Please try again later.";
      case STATE_UNVERIFIED:
        return error?.message;
      default:
        return "Chain of verification confirmed";
    }
  };

  const STATUS_ERROR = "NOT SENT";
  const STATUS_NOT_VERIFIED = "NOT SENT";
  const STATUS_PENDING = "WAITING...";
  const STATUS_COMPLETED = "SENT";
  const [ titleState, setTitleState ] = useState('WAITING...')
  const [ titleStateSeal, setTitleStateSeal ] = useState('WAITING...')
  const [ stateClasses, setStateClasses ] = useState("details__steps-cards__verification details__steps-cards__verification--center")
  const [ stateClassesSeal, setStateClassesSeal ] = useState("details__steps-cards__verification details__steps-cards__verification--center")

  const getRecipientsAmount = (messageData?: MessageData): number => {
    let amount = 0;

    messageData?.recipients &&
      Object.values(messageData?.recipients).forEach((recipientsList) => {
        if (Array.isArray(recipientsList)) {
          amount += recipientsList.length;
        }
      });

    return amount;
  };
  const recipientsAmount = getRecipientsAmount(qrCodeData);


  function statusesInfo(verState: number) {
    if (verState === STATE_ERROR) {
      setTitleState(STATUS_ERROR);
      setTitleStateSeal('NOT SEALED');
      setStateClasses("details__steps-cards__verification details__steps-cards__verification--center details__steps-cards__verification--error")
      setStateClassesSeal("details__steps-cards__verification details__steps-cards__verification--center details__steps-cards__verification--error")
      return
    } 
    if (verState === STATE_UNVERIFIED) {
      setTitleState(STATUS_NOT_VERIFIED);
      setTitleStateSeal('NOT SEALED')
      setStateClasses("details__steps-cards__verification details__steps-cards__verification--center details__steps-cards__verification--error")
      setStateClassesSeal("details__steps-cards__verification details__steps-cards__verification--center details__steps-cards__verification--error")
      return
    } 
    if (
      verState === STATE_STATUS_DATA_PENDING ||
      verState === STATE_TAIL_PENDING ||
      verState === STATE_PENDING
    ) {
      setTitleState(STATUS_PENDING);
      setTitleStateSeal('WAITING...');
      setStateClasses("details__steps-cards__verification details__steps-cards__verification--center")
      setStateClassesSeal("details__steps-cards__verification details__steps-cards__verification--center") 
    } 
    if (STATE_LOADING_STATUS_DATA < verState) {
      setTitleState(STATUS_COMPLETED);
      setStateClasses("details__steps-cards__verification details__steps-cards__verification--center details__steps-cards__verification--success")
    } 
    
    if (STATE_VERIFYING_SENDER < verState) {
      setTitleStateSeal('SEALED');
      setStateClassesSeal("details__steps-cards__verification details__steps-cards__verification--center details__steps-cards__verification--success")
    }
  }

  useEffect(() => {
    statusesInfo(verificationState)
  }, [verificationState]);

  return (
    <div className={className}>
      <section className="details__progress">
        <p className="details__title">Details</p>
        <p className="details__progress__status-text">
          {getVerificationStatusText()}
        </p>
        <Card className="details__steps-cards" color="white">
          <VerificationStepCard
            title={titleState}
            value={sentTime ? format(sentTime, "dd/MM/yy\xa0HH:mm:ss") : ""}
            targetState={STATE_LOADING_STATUS_DATA}
            verificationState={verificationState}
            addClass={stateClasses}
            iconClass={'details__steps-cards__verification--hideicon'}
          />
          <VerificationStepCard
            title={titleStateSeal}
            value={sealTime ? format(sealTime, "dd/MM/yy\xa0HH:mm:ss") : ""}
            targetState={STATE_VERIFYING_SENDER}
            verificationState={verificationState}
            addClass={stateClassesSeal}
            iconClass={'details__steps-cards__verification--hideicon'}
          />
        </Card>
        <Card className="details__steps-cards " color="white">
          <VerificationStepCard
            title="Confirmations"
            value={
              (() => {
                if (
                  verificationState === STATE_UNVERIFIED ||
                  verificationState === STATE_ERROR
                ) {
                  return "Unverified";
                }
  
                if (
                  verificationState > STATE_VERIFYING_RECEIVERS &&
                  numReceiversVerified !== 0 &&
                  recipientsAmount !== 0
                ) {
                    return `Received and verified by\xa0${numReceiversVerified}/${recipientsAmount} recipients`
                } else {
                  return "Pending verification";
                }
              })()
            }
            targetState={STATE_VERIFYING_RECEIVERS}
            verificationState={
              verificationState > STATE_VERIFYING_RECEIVERS &&
              numReceiversVerified === 0 &&
              recipientsAmount !== 0
                ? STATE_PENDING
                : verificationState
            }
            iconClass='white-card__icon'
            addClass="details__steps-cards__verification details__steps-cards__verification--white details__steps-cards__verification--confirmation"
          />
          <VerificationStepCard
            title="Attachments"
            value={(() => {
              if (
                verificationState === STATE_UNVERIFIED ||
                verificationState === STATE_ERROR
              ) {
                return "Unverified";
              }

              if (
                qrCodeData &&
                verificationState > STATE_VERIFYING_ATTACHMENTS
              ) {
                if (numReceiversVerified > 0) {
                  return qrCodeData?.attachments?.length
                    ? "Verified"
                    : "No attachments";
                } else {
                  return "Pending verification";
                }
              }
              return "";
            })()}
            targetState={STATE_VERIFYING_ATTACHMENTS}
            verificationState={
              verificationState > STATE_VERIFYING_ATTACHMENTS &&
              numReceiversVerified === 0 &&
              recipientsAmount !== 0
                ? STATE_PENDING
                : verificationState
            }
            iconClass='white-card__icon'
            addClass="details__steps-cards__verification details__steps-cards__verification--white details__steps-cards__verification--confirmation"
          />
        </Card>
      </section>

      <section className="double-column">
        <BlockchainInfo
          verificationDetails={senderVerificationDetails}
          statusData={senderStatusData}
          statusId={sealId}
        />

        <IPFSInfo qrCodeData={qrCodeData} />
      </section>
    </div>
  );
};

export default Details;
