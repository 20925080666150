import { useEffect, useState } from "react";
import { getTotalSealedCount } from "../services/API";

export default () => {
  const [totalSealed, setTotalSealed] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  /**
   * Reads QR payload
   */
  useEffect(() => {
    const requestTotalSealed = async () => {
      try {
        setLoading(true);
        const count = await getTotalSealedCount();
        setTotalSealed(count);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    requestTotalSealed();
  }, []);

  return {
    totalSealed,
    loading,
    error,
  };
};
