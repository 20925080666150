import React from "react";
import "./IPFSCard.scss";
import { MessageData } from "@vereign/light-utils";
import DetailsCard from "../../../../components/DetailsCard";
import messagesIcon from "../../../../assets/images/seal_e-mail/content.svg";
import useIpfs from "../../../../hooks/useIpfs";

interface Props {
  qrCodeData?: MessageData;
}

const IPFS_CONTENT_ID = "ipfs-html";
// const MAX_HEIGHT = 500; // px

const IPFSCard = ({ qrCodeData }: Props) => {
  // const [enabled, setEnabled] = useState(false);
  // const [expanded, setExpanded] = useState(false);
  // const [size, setSize] = useState([0, 0]);

  const ipfs = qrCodeData?.ipfs;
  const { html, plainText } = useIpfs(ipfs);

  // useEffect(() => {
  //   if (!html) return;

  //   const htmlNode = document.getElementById(IPFS_CONTENT_ID);

  //   if (!htmlNode) return;

  //   if (htmlNode.clientHeight > MAX_HEIGHT) {
  //     setEnabled(true);
  //   } else {
  //     setEnabled(false);
  //   }
  // }, [html, plainText, size]);

  // useLayoutEffect(() => {
  //   function updateSize() {
  //     setSize([window.innerWidth, window.innerHeight]);
  //   }
  //   window.addEventListener("resize", updateSize);
  //   updateSize();
  //   return () => window.removeEventListener("resize", updateSize);
  // }, []);

  if (!ipfs) return null;

  return (
    <DetailsCard
      className="ipfs-card"
      color="white"
      iconSrc={messagesIcon}
      iconBgColor="turqoiseSurf"
    >
      <section className="ipfs-card__section-main">
        <h1 className="ipfs-card__header">{(qrCodeData && qrCodeData.subject) ? qrCodeData.subject : '<No subject.>'}</h1>
        <div
          className="ipfs-card__content ipfs-card__content--full"
        >
          {!!html && (
            <div
              id={IPFS_CONTENT_ID}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          )}
          {!html && !!plainText && (
            <div id={IPFS_CONTENT_ID} className="ipfs-card__plainText">
              <pre>{plainText}</pre>
            </div>
          )}
          {!html && !plainText &&
            <div style={{marginLeft: '0px', color: 'gray'}}>
              {`<No email body.>`}
            </div>
          }
        </div>
      </section>
    </DetailsCard>
  );
};

export default IPFSCard;
