import React from "react";
import classnames from "classnames";

import "./DetailsCard.scss";
import Card, { ColorsType } from "../Card";

export type IconBgType = "white" | "black" | "turqoiseSurf";

interface Props {
  className?: string;
  iconClassName?: string;
  children: JSX.Element | JSX.Element[];
  color: ColorsType;
  iconSrc?: any;
  iconBgColor?: IconBgType;
}

// const ICON_BG_COLORS = {
//   white: "details-card__circle--white",
//   black: "details-card__circle--black",
//   turqoiseSurf: 'details-card__circle--turq'
// };

const DetailsCard = ({
  iconSrc,
  iconBgColor = "white",
  children,
  className,
  iconClassName,
  color,
}: Props) => {
  return (
    <Card className={classnames("details-card", className)} color={color}>
      {children}
    </Card>
  );
};

export default DetailsCard;
