import React from "react";
import classnames from "classnames";

import "./Icon.scss";

interface Props {
  src: any;
  width?: number;
  height?: number;
  className?: string;
  alt?: string;
  onClick?: (e: any) => void;
}

const Icon = ({ src, className, width, height, alt, onClick }: Props) => {
  return (
    <img
      src={src}
      width={width}
      height={height}
      onClick={onClick}
      className={classnames("check-mark", className)}
      alt={alt}
    />
  );
};

Icon.defaultProps = {
  width: 32,
  height: 32,
  alt: "Icon",
};

export default Icon;
