import React, { useEffect, useState } from "react";

import "./VerificationStepCard.scss";
import Spinner from "../../../../components/Spinner";
import Icon from "../../../../components/Icon";

import checkmark from "../../../../assets/images/seal_e-mail/confirmed.svg";
import question from "../../../../assets/images/question.svg";
import hourglass from "../../../../assets/images/hourglass-step.svg";
import exclamation from "../../../../assets/images/exclamationCircle.svg";
import {
  STATE_ERROR,
  STATE_PENDING,
  STATE_STATUS_DATA_PENDING,
  STATE_TAIL_PENDING,
  STATE_UNVERIFIED,
} from "../../../../hooks/useVerificationFlow";

interface Props {
  title: string;
  value?: any;
  targetState: number;
  verificationState: number;
  line?: boolean;
  iconClass?: string;
  addClass?: string;
}

const STATUS_ERROR = "STATUS_ERROR";
const STATUS_NOT_VERIFIED = "STATUS_NOT_VERIFIED";
const STATUS_PENDING = "STATUS_PENDING";
const STATUS_LOADING = "STATUS_LOADING";
const STATUS_COMPLETED = "STATUS_COMPLETED";

const VerificationStepCard = ({
  title,
  value,
  targetState,
  verificationState,
  iconClass,
  addClass
}: Props) => {
  const [status, setStatus] = useState<string>(STATUS_LOADING);

  useEffect(() => {
    if (verificationState === STATE_ERROR) {
      setStatus(STATUS_ERROR);
    } else if (verificationState === STATE_UNVERIFIED) {
      setStatus(STATUS_NOT_VERIFIED);
    } else if (
      verificationState === STATE_STATUS_DATA_PENDING ||
      verificationState === STATE_TAIL_PENDING ||
      verificationState === STATE_PENDING
    ) {
      setStatus(STATUS_PENDING);
    } else if (targetState < verificationState) {
      setStatus(STATUS_COMPLETED);
    }
  }, [targetState, verificationState, status]);

  return (
    <div className={"verification-step "+addClass} color="white">
      <div className="verification-step__info">
        <p className="verification-step__title">{title}</p>
        <p className="verification-step__value">{value}</p>
      </div>
      {status === STATUS_LOADING ? (
        <Spinner className={`verification-step__icon ${iconClass}`} />
      ) : status === STATUS_COMPLETED ? (
        <Icon src={checkmark} className={`verification-step__icon ${iconClass}`} />
      ) : status === STATUS_PENDING ? (
        <Icon src={hourglass} className={`verification-step__icon ${iconClass}`} />
      ) : status === STATUS_NOT_VERIFIED ? (
        <Icon src={question} className={`verification-step__icon ${iconClass}`} />
      ) : (
        <Icon src={exclamation} className={`verification-step__icon ${iconClass}`} />
      )}
    </div>
  );
};

VerificationStepCard.defaultProps = {
  status: STATUS_LOADING,
};

export default VerificationStepCard;
