import React from "react";

import "./TotalSealedCard.scss";
import Card from "../../../../components/Card";
import useTotalSealed from "../../../../hooks/useTotalSealed";

const numberWithCommas = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const TotalSealedCard = () => {
  const { totalSealed } = useTotalSealed();

  return (
    <Card className="total-sealed-card" color="blackLive">
      <div className="total-sealed-card__title">Web3 digital twins generated:</div>
      <div className="total-sealed-card__counter">
        {numberWithCommas(totalSealed)}
      </div>
    </Card>
  );
};

export default TotalSealedCard;
